<template>
  <div>
    
  </div>
  <router-view/>
</template>

<style lang="less" >
// 通用样式
  *{
    margin: 0;
    padding: 0;
    a{
      text-decoration: none;
    color: #337ab7;

    }
    ul{
      list-style: none;
    }
  }
  @media (min-width: 768px) {
  .container {
    width: 750px;
  }
}

@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}
.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
img{
    vertical-align: middle;
}
.text_center {
    text-align: center;
}
.top_advBox {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
}

</style>
