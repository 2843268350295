import { createStore } from 'vuex'

export default createStore({
  state: {
    vuexAdvActive:0, //导航选中数
    laboratoryActive:0
  },
  mutations: {
    // 修改导航被选中数
    modifyVuexAdvActive(state,payload){
      state.vuexAdvActive=payload.vuexAdvActive
    },
    modifyVuexLaboratoryActive(state,payload){
      state.laboratoryActive=payload
    }
  },
  actions: {
  },
  modules: {
  }
})
