<template>
  <div class="index_layout">
    <!-- 顶部导航 -->
    <div class="top_advBox">
      <TopAdv :bgnColor="0"></TopAdv>
    </div>
    <!--首页大轮播 -->
    <div>
      <IndexCarousel></IndexCarousel>
    </div>
  </div>
</template>
<script>
import TopAdv from "../../components/TopAdv";
import IndexCarousel from "./IndexCarousel";

export default {
  name: "IndexLayOut",
  data() {
    return {};
  },
  components: {
    TopAdv,
    IndexCarousel,
  },
    created () {
        // 修改顶部导航当前选中
        this.$store.commit('modifyVuexAdvActive',{vuexAdvActive:0})
    }
  
};
</script>
<style scoped lang="less">

.index_layout {
  background-image: url(../../assets/indexImg/index_bg.jpg);
  width: 100%;
  min-width: 1200px;
  height: 100%;
  position: fixed;
  background-size: 100%;
}
</style>
    