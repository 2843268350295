import { createRouter, createWebHistory } from 'vue-router'
import IndexLayOut from '../views/index/IndexLayOut.vue'

const routes = [
  // 首页
  {
    path: '/',
    name: 'IndexLayOut',
    component:IndexLayOut
  },
  // 产品页
  {
    path: '/productPage',
    name: 'productPage',

    component: () => import( '../views/product/ProductLayOut')
  },
  // 产品详情
  {
    path: '/productDetil',
    name: 'productDetil',

    component: () => import( '../views/productDetil/ProductDetil')
  },
  // 新闻页
  {
    path: '/news',
    name: 'newsLayOut',

    component: () => import( '../views/news/NewsLayOut')
  },
  //新闻详情
  {
   path:'/newsDetail',
   name: 'newsDetail',
   component:()=>import('../views/news/components/NewsDetil')
  },
  //关于我们
  {
   path:'/aboutUs',
   name: 'aboutUs',
   component:()=>import('../views/aboutUs/AboutUsLayOut')
  },
  {
    path:'/laboratory',
    name: 'laboratory',
    component:()=>import('../views/laboratory/LaboratoryLayOut')
  },
  {
    path:'/laboratoryDetail',
    name: 'laboratoryDetail',
    component:()=>import('../views/laboratory/components/LaboratoryDetail')
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach(async (to, from, next) => {
  if (to.path) {
    if (window._hmt) {
      window._hmt.push(['_trackPageview', '/#' + to.fullPath]);
    }
  }
  next();
});

export default router
