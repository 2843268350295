import req from "../utils/http.js"

//target: 'http://www.shangyuninfo.com', 不用去除api
// 数据列表(轮播图,图片)
// 产品列表  首页第一轮播图判断homepagebroadcast==1是第一个轮播图数据 第二轮判断enabled==1时是第二个轮播图数据
//产品列表页  上边两个大图判断item.route有值 并且item.productSort == 0;
//下面的小方块数据为取反
export function getProductList (id="") {
    return req.post( `/api/system/chanpin/list/${id}` )
}


// 新闻列表 id为空时返回列表,输入具体id是详情
export function getNewsList (id="") {
    return req.post( `/api/system/xinwen/list/${id}` )
}
//联系我们 底部信息栏联系我们接口
export function connectUs () {
    return req.post( '/api/system/dict/company' )
}
//     //二维码 底部二维码
export function qrCode () {
    return req.post( '/api/system/dict/qrcode' )
}

//星火实验室项目展示  和新闻列表一样的道理, 有具体id就是详情页
export function getLaboratoryList (id="") {
    return req.post( `/api/system/spark/project/list/${id}` )
}
//星火实验室列表新闻展示 和上边一样 有具体id就是详情页数据
export function getLaboratoryNews (id="") {
    return req.post( `/api/system/spark/xinwen/list/${id}` )
}


