// 配置babel
import '@babel/polyfill'
import Es6Promise from 'es6-promise'
Es6Promise.polyfill()

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// element组件引入
import ElementPlus from 'element-plus';
import 'element-plus/lib/theme-chalk/index.css';
import 'element-plus/lib/theme-chalk/display.css';
import VueAMap from 'vue-amap'
// swiper 配置
import SwiperCore, { Mousewheel, Pagination, EffectFlip, EffectCube, EffectFade,Autoplay ,EffectCoverflow,Navigation} from 'swiper';
import 'swiper/swiper.min.css';
import 'swiper/swiper.less';
import 'swiper/components/navigation/navigation.less'
import 'swiper/components/pagination/pagination.less'
import 'swiper/components/scrollbar/scrollbar.less'
import 'swiper/components/pagination/pagination.min.css'
import 'swiper/components/navigation/navigation.min.css'
import 'swiper/components/effect-coverflow/effect-coverflow.min.css';
SwiperCore.use([Mousewheel, Pagination, EffectFlip, EffectCube, EffectFade,Autoplay,EffectCoverflow,Navigation]); 
createApp(App).use(ElementPlus).use(store).use(router).use(VueAMap).mount('#app')